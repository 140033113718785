import Grid2 from '@mui/material/Unstable_Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import { getFieldHelperText } from '../../utils/utils'
import BaseTextField from '../components/textfield/BaseTextField'
export default function PersonalInformationFields(props) {
  const {
    firstName,
    setFirstName,
    validateFirstName,
    lastName,
    setLastName,
    validateLastName,
    email,
    setEmail,
    validateEmail,
    phoneNumber,
    setPhoneNumber,
    validatePhoneNumber,
  } = props

  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <span className="media-pocket-purple">Personal information</span>
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <BaseTextField
          name="firstName"
          id="firstName-input"
          label="First name"
          onChange={(e) =>
            setFirstName({ ...firstName, value: e.target.value })
          }
          onBlur={() => validateFirstName()}
          error={firstName.error}
          helperText={getFieldHelperText(firstName)}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <BaseTextField
          name="lastName"
          id="lastName-input"
          label="Last name"
          onChange={(e) => setLastName({ ...lastName, value: e.target.value })}
          onBlur={() => validateLastName()}
          error={lastName.error}
          helperText={getFieldHelperText(lastName)}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <BaseTextField
          name="email"
          id="email-input"
          placeholder="example@domain.com"
          label="Email"
          onChange={(e) => setEmail({ ...email, value: e.target.value })}
          onBlur={() => validateEmail()}
          error={email.error}
          helperText={getFieldHelperText(email)}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <BaseTextField
          name="phoneNumber"
          id="phoneNumber-input"
          placeholder="0123456789 or +358123456789"
          label="Phone number"
          onChange={(e) =>
            setPhoneNumber({ ...phoneNumber, value: e.target.value })
          }
          onBlur={() => validatePhoneNumber()}
          error={phoneNumber.error}
          helperText={getFieldHelperText(phoneNumber)}
          required={false}
        />
      </Grid2>
    </Grid2>
  )
}

PersonalInformationFields.propTypes = {
  firstName: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setFirstName: PropTypes.func.isRequired,
  validateFirstName: PropTypes.func.isRequired,
  lastName: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setLastName: PropTypes.func.isRequired,
  validateLastName: PropTypes.func.isRequired,
  email: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setEmail: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
  phoneNumber: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setPhoneNumber: PropTypes.func.isRequired,
  validatePhoneNumber: PropTypes.func.isRequired,
}
