import PropTypes from 'prop-types'
import React from 'react'
import StyledTextField from './StyledTextField'

export default function BaseTextField(props) {
  const {
    id,
    name,
    label,
    error,
    helperText,
    onChange,
    onBlur,
    placeholder,
    required,
    type,
  } = props

  return (
    <StyledTextField
      variant="standard"
      id={id}
      name={name}
      label={label}
      error={error}
      helperText={helperText}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={placeholder}
      InputLabelProps={{ shrink: true }}
      InputProps={{ disableUnderline: true }}
      style={{ width: '100%' }}
      required={required}
      autoComplete="off"
      type={type}
    />
  )
}

BaseTextField.defaultProps = {
  name: 'name',
  placeholder: '',
  label: 'label',
  error: false,
  helperText: '',
  onBlur: () => {},
  required: true,
  type: 'text',
}

BaseTextField.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
}
