import Button from '@mui/material/Button'
import React, { useContext } from 'react'
import AuthContext from '../../../context/AuthContext'

export default function LogoutButton() {
  const { logoutUser, user } = useContext(AuthContext)

  return (
    <>
      <span style={{ color: '#35958a' }}>{user.username}</span>
      <Button
        variant="string"
        onClick={() => logoutUser()}
        data-cy="logout-button"
      >
        Log out
      </Button>
    </>
  )
}
