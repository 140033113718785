import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

const StyledTextField = styled(TextField)`
  min-height: 94px;
  .MuiInput-input {
    margin-top: 12px;
    border-bottom: 1px solid #474747;
    color: #eaeaea;
    font-size: 18px;
    font-weight: 300;
    overflow: hidden;
    text-overflow: ellipsis;
    min-height: 26px;
  }
  .MuiInputLabel-root {
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    &.Mui-focused {
      color: #6b5b95;
      .MuiFormLabel-asterisk {
        &.Mui-error {
          color: #6b5b95;
        }
      }
    }
  }
  .MuiFormHelperText-root {
    font-size: 16px;
    color: #baa0ff;
  }
`

export default StyledTextField
