import Card from '@mui/material/Card'
import Grid2 from '@mui/material/Unstable_Grid2'
import { Uppy as UppyCore } from '@uppy/core'
import Form from '@uppy/form'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState, useRef } from 'react'
import PersonalInformationFields from './PersonalInformationFields'
import ProgramInformationFields from './ProgramInformationFields'
import { introValidation, bothIntrosValidation } from './functions/introValidation'
import { splitTimeCodeValidation } from './functions/splitTimecodeValidation'
import { segmentValidation } from './functions/segmentValidation'
import {
  emailValidation, firstNameValidation, lastNameValidation,
  phoneNumberValidation, houseIdValidation,
} from './functions/personalInformationValidation'

export default function MetadataForm(props) {
  const { uppy, triggerValidateFields } = props

  const [firstName, setFirstName] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [lastName, setLastName] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [email, setEmail] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [phoneNumber, setPhoneNumber] = useState({
    value: null,
    error: false,
    errorMessage: '',
    required: false,
  })
  const [houseId, setHouseId] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [splitTimecode, setSplitTimecode] = useState({
    value: null,
    error: null,
    errorMessage: '',
    required: true,
  })
  const [introStart, setIntroStart] = useState({
    value: null,
    error: false,
    errorMessage: '',
    required: false,
  })
  const [introEnd, setIntroEnd] = useState({
    value: null,
    error: false,
    errorMessage: '',
    required: false,
  })
  const [programInformation, setProgramInformation] = useState([
    {
      segmentType: 'Start',
      value: '00:00:00:00',
      error: false,
      errorMessage: '',
      id: 0,
      required: true,
    },
    {
      segmentType: 'End',
      value: null,
      error: null,
      errorMessage: '',
      id: 0,
      required: true,
    },
  ])
  const prevSegmentLength = useRef(programInformation.length)

  const validateFirstName = useCallback(() => {
    firstNameValidation({ firstName, setFirstName })
  }, [firstName])

  const validateLastName = useCallback(() => {
    lastNameValidation({ lastName, setLastName })
  }, [lastName])

  const validateEmail = useCallback(() => {
    emailValidation({ email, setEmail })
  }, [email])

  const validatePhoneNumber = useCallback(() => {
    phoneNumberValidation({ phoneNumber, setPhoneNumber })
  }, [phoneNumber])

  const validateHouseId = useCallback(() => {
    houseIdValidation({ houseId, setHouseId })
  }, [houseId])

  const validateIntroPosition = useCallback(({ validateOther, position }) => {
    introValidation({
      introStart, introEnd, setIntroStart, setIntroEnd,
      validateOther, position, programInformation
    })
  }, [introEnd, introStart, programInformation])

  const validateBothIntros = useCallback(() => {
    bothIntrosValidation({
      introStart, introEnd, setIntroStart, setIntroEnd,
      programInformation,
    })
  }, [introEnd, introStart, programInformation])

  const validateSplitTimecode = useCallback(() => {
    splitTimeCodeValidation({ splitTimecode, setSplitTimecode, programInformation })
  }, [splitTimecode, programInformation])

  const validateAllSegments = useCallback(() => {
    segmentValidation({ programInformation, setProgramInformation, setSplitTimecode, splitTimecode })
  }, [programInformation, splitTimecode])

  // useEffect used to reset segment validation when a segment is removed
  useEffect(() => {
    if (prevSegmentLength.current === programInformation.length) return
    if (prevSegmentLength.current < programInformation.length) {
      prevSegmentLength.current = programInformation.length
      return
    }

    prevSegmentLength.current = programInformation.length
    validateAllSegments()
  }, [programInformation.length, validateAllSegments])

  const validateFields = useCallback(() => {
    validateFirstName()
    validateLastName()
    validateEmail()
    validatePhoneNumber()
    validateHouseId()
    validateSplitTimecode()
    validateAllSegments()
    validateBothIntros()
  }, [
    validateFirstName,
    validateLastName,
    validateEmail,
    validatePhoneNumber,
    validateHouseId,
    validateSplitTimecode,
    validateAllSegments,
    validateBothIntros,
  ])

  useEffect(() => {
    const isValidFirstName = firstName.error === false
    const isValidLastName = lastName.error === false
    const isValidEmail = email.error === false
    const isValidPhoneNumber = phoneNumber.error === false
    const isValidHouseId = houseId.error === false
    const isValidSplitTimecode = splitTimecode.error === false
    const isValidIntroStart = introStart.error === false
    const isValidIntroEnd = introEnd.error === false
    const isValidProgramInformation = programInformation.every(
      (segment) => segment.error === false
    )
    const allValid =
      isValidFirstName &&
      isValidLastName &&
      isValidEmail &&
      isValidPhoneNumber &&
      isValidHouseId &&
      isValidSplitTimecode &&
      isValidIntroStart &&
      isValidIntroEnd &&
      isValidProgramInformation
    uppy.setMeta({ readyForUpload: allValid })
  })

  useEffect(() => {
    if (triggerValidateFields) {
      validateFields()
    }
  }, [triggerValidateFields, validateFields])

  useEffect(() => {
    uppy.use(Form, {
      target: '#metadata_form',
    })
  }, [uppy])

  return (
    <Grid2 xs={12}>
      <form id="metadata_form">
        <Grid2 xs={12}>
          <Card>
            <PersonalInformationFields
              firstName={firstName}
              setFirstName={setFirstName}
              validateFirstName={validateFirstName}
              lastName={lastName}
              setLastName={setLastName}
              validateLastName={validateLastName}
              email={email}
              setEmail={setEmail}
              validateEmail={validateEmail}
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              validatePhoneNumber={validatePhoneNumber}
            />
          </Card>
        </Grid2>
        <Grid2 xs={12}>
          <Card>
            <ProgramInformationFields
              programInformation={programInformation}
              setProgramInformation={setProgramInformation}
              validateAllSegments={validateAllSegments}

              houseId={houseId}
              setHouseId={setHouseId}
              validateHouseId={validateHouseId}

              splitTimecode={splitTimecode}
              setSplitTimecode={setSplitTimecode}
              validateSplitTimecode={validateSplitTimecode}

              introStart={introStart}
              setIntroStart={setIntroStart}
              validateIntroStart={() => validateIntroPosition({ validateOther: true, position: 'start' })}

              introEnd={introEnd}
              setIntroEnd={setIntroEnd}
              validateIntroEnd={() => validateIntroPosition({ validateOther: true, position: 'end' })}
            />
          </Card>
        </Grid2>
      </form>
    </Grid2>
  )
}

MetadataForm.propTypes = {
  uppy: PropTypes.instanceOf(UppyCore).isRequired,
  triggerValidateFields: PropTypes.bool.isRequired,
}
