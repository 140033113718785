import axios from 'axios'
import jwt_decode from 'jwt-decode'
import PropTypes from 'prop-types'
import React, { createContext, useState } from 'react'

const AuthContext = createContext()

export default AuthContext

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem('authTokens')
      ? JSON.parse(localStorage.getItem('authTokens'))
      : null
  )
  const [user, setUser] = useState(() =>
    localStorage.getItem('authTokens')
      ? jwt_decode(JSON.parse(localStorage.getItem('authTokens'))?.access)
      : null
  )
  const loginUser = async (username, password) => {
    const response = await axios
      .post('/api/auth/login/', { username, password })
      .then((res) => {
        if (res.status === 200) {
          setAuthTokens(res.data)
          setUser(jwt_decode(res.data.access))
          localStorage.setItem('authTokens', JSON.stringify(res.data))
        }
        return res
      })
      .catch((error) => error.response)
    return response
  }

  const logoutUser = React.useCallback(async () => {
    await axios
      .post(
        '/api/auth/logout/',
        { refresh: JSON.parse(localStorage.getItem('authTokens'))?.refresh },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage.getItem('authTokens'))?.access
            }`,
          },
        }
      )
      .catch(() => {})
      .finally(() => {
        setUser(null)
        setAuthTokens(null)
        localStorage.removeItem('authTokens')
      })
  }, [])

  const refreshAuthToken = React.useCallback(async () => {
    await axios
      .post('/api/auth/refresh/', {
        refresh: JSON.parse(localStorage.getItem('authTokens'))?.refresh,
      })
      .then((res) => {
        if (res.status === 200) {
          setAuthTokens(res.data)
          setUser(jwt_decode(res.data.access))
          localStorage.setItem('authTokens', JSON.stringify(res.data))
        }
        return res
      })
      .catch(() => logoutUser())
  }, [logoutUser])

  const isAccessTokenExpired = React.useCallback(() => {
    const token = JSON.parse(localStorage.getItem('authTokens'))?.access
    if (token === undefined) return false

    const decodedToken = jwt_decode(token)
    return Math.floor(Date.now() / 1000) >= decodedToken.exp
  }, [])

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    loginUser,
    logoutUser,
    refreshAuthToken,
    isAccessTokenExpired,
  }
  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
