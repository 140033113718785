import Container from '@mui/material/Container'
import Grid2 from '@mui/material/Unstable_Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import Footer from './Footer'
import Header from './Header'

export default function Layout(props) {
  const { children } = props
  return (
    <Container>
      <Grid2 container spacing={2} style={{ minHeight: '100%' }}>
        <Header />
        {children}
        <Footer />
      </Grid2>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
