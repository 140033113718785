import PropTypes, { string } from 'prop-types'
import React from 'react'
import InputMask from 'react-input-mask'
import StyledTextField from './StyledTextField'

export default function MaskedTextField(props) {
  const {
    mask,
    id,
    name,
    label,
    value,
    error,
    helperText,
    onChange,
    onBlur,
    placeholder,
    disabled,
    required,
  } = props

  return (
    <InputMask
      mask={mask}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      maskPlaceholder={null}
      disabled={disabled}
    >
      <StyledTextField
        id={id}
        name={name}
        variant="standard"
        InputLabelProps={{ shrink: true }}
        InputProps={{ disableUnderline: true }}
        style={{ width: '100%' }}
        placeholder={placeholder}
        label={label}
        error={error}
        helperText={helperText}
        required={required}
        autoComplete="off"
      />
    </InputMask>
  )
}

MaskedTextField.defaultProps = {
  name: 'name',
  placeholder: 'placeholder',
  label: 'label',
  error: false,
  helperText: 'helperText',
  onBlur: () => {},
  disabled: false,
  required: false,
}

MaskedTextField.propTypes = {
  mask: PropTypes.string.isRequired,
  id: string.isRequired,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
}
