import PropTypes from 'prop-types'
import React from 'react'
import { getFieldHelperText } from '../../utils/utils'
import MaskedTextField from '../components/textfield/MaskedTextField'

export default function IntroField({
  intro, setIntro,
  validateIntro, position,
}) {
  return (
    <MaskedTextField
      mask="99:99:99:99"
      id={`intro_${position.toLowerCase()}-input`}
      name={`intro_${position.toLowerCase()}`}
      placeholder="00:00:00:00"
      label={`Intro ${position}`}
      value={intro.value || ''}
      onChange={(e) =>
        setIntro({ ...intro, value: e.target.value })
      }
      onBlur={() => validateIntro()}
      error={intro.error}
      helperText={getFieldHelperText(intro)}
      required={false}
    />
  )
}

IntroField.propTypes = {
  intro: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setIntro: PropTypes.func.isRequired,
  validateIntro: PropTypes.func.isRequired,
  position: PropTypes.string.isRequired,
}
