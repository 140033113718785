import Grid2 from '@mui/material/Unstable_Grid2'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import LoginButton from './button/LoginButton'
import BaseTextField from './textfield/BaseTextField'

export default function LoginContainer() {
  const { loginUser } = useContext(AuthContext)
  const [loginDisabled, setLoginDisabled] = useState(true)
  const [processingLogin, setProcessingLogin] = useState(false)
  const [loginError, setLoginError] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  useEffect(() => {
    setLoginDisabled(username.length < 1 || password.length < 1)
  }, [username, password])

  const handleSubmit = async () => {
    if (!loginDisabled) {
      setLoginError('')
      setProcessingLogin(true)
      const response = await loginUser(username, password)
      if (response?.status === 200) {
        navigate('/home')
      } else if (response?.status === 401) {
        setProcessingLogin(false)
        setLoginError('Invalid username or password!')
      } else {
        setProcessingLogin(false)
        setLoginError(
          'Unexpected error while logging in, please contact support!'
        )
      }
    }
  }

  return (
    <Grid2 xs={12}>
      <form style={{ width: '100%' }}>
        <Grid2 xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid2 xs={8} md={4}>
            <BaseTextField
              id="username-input"
              onChange={(e) => setUsername(e.target.value)}
              label="Username"
              required={false}
            />
          </Grid2>
        </Grid2>
        <Grid2 xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <Grid2 xs={8} md={4}>
            <BaseTextField
              id="password-input"
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              required={false}
              type="password"
            />
          </Grid2>
        </Grid2>
        <Grid2 xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          {loginError ? (
            <span className="media-pocket-purple" data-cy="login-error">
              {loginError}
            </span>
          ) : (
            <wbr />
          )}
        </Grid2>
        <Grid2
          xs={12}
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '20px',
          }}
        >
          <LoginButton
            loginDisabled={loginDisabled}
            processingLogin={processingLogin}
            handleSubmit={handleSubmit}
          />
        </Grid2>
      </form>
    </Grid2>
  )
}
