import { IconButton, Tooltip, Typography } from '@mui/material'
import PropTypes from 'prop-types'
import React from 'react'

export default function CustomIconButton(props) {
  const { tooltip, icon, alt, onClick, style, 'data-cy': dataCy } = props

  return (
    <Tooltip
      title={<Typography style={{ fontSize: 14 }}>{tooltip}</Typography>}
    >
      <IconButton onClick={onClick} style={style} data-cy={dataCy}>
        {icon && icon !== '' ? (
          <img src={icon} alt={alt} height="24px" width="24px" />
        ) : null}
      </IconButton>
    </Tooltip>
  )
}

CustomIconButton.defaultProps = {
  style: {},
}

CustomIconButton.propTypes = {
  tooltip: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  'data-cy': PropTypes.string.isRequired,
}
