import {
  isEmptyInput,
  isInvalidSplitTimecode,
  isInvalidTimecode,
  startGreaterThanEnd,
  startLessThanEnd,
} from '../../../utils/utils'

const validateSegment = (previous, current, next, state) => {
  const {
    programInformation, setProgramInformation, setSplitTimecode, splitTimecode,
  } = state
  const programInformationCopy = [...programInformation]
  const currentSegmentCopy = programInformationCopy.find(
    (e) => e.id === current.id && e.segmentType === current.segmentType
  )
  if (isEmptyInput(currentSegmentCopy.value)) {
    currentSegmentCopy.error = true
    currentSegmentCopy.errorMessage = 'Timecode can not be empty'
  } else if (isInvalidTimecode(currentSegmentCopy.value)) {
    currentSegmentCopy.error = true
    currentSegmentCopy.errorMessage =
      'Please enter a valid timecode in format HH:MM:SS:FF'
  } else if (
    previous &&
    !startGreaterThanEnd(currentSegmentCopy.value, previous.value)
  ) {
    currentSegmentCopy.error = true
    currentSegmentCopy.errorMessage =
      'Timecode must be greater than the last timecode'
  } else if (
    next &&
    !startLessThanEnd(currentSegmentCopy.value, next.value)
  ) {
    currentSegmentCopy.error = true
    currentSegmentCopy.errorMessage =
      'Timecode must be less than the next timecode'
  } else if (
    currentSegmentCopy.segmentType === 'End' &&
    currentSegmentCopy.id === programInformationCopy.length / 2 - 1
  ) {
    currentSegmentCopy.error = false
    currentSegmentCopy.errorMessage = ''
    if (isEmptyInput(splitTimecode.value)) {
      setSplitTimecode({
        ...splitTimecode,
        error: true,
        errorMessage: 'Split timecode is required',
      })
    } else if (
      isInvalidSplitTimecode(splitTimecode.value, currentSegmentCopy.value)
    ) {
      setSplitTimecode({
        ...splitTimecode,
        error: true,
        errorMessage: `Split timecode must be at least 15 seconds before segment ${
          currentSegmentCopy.id + 1
        } end`,
      })
    } else if (
      !startGreaterThanEnd(
        splitTimecode.value,
        programInformation.slice(-2)[0].value
      )
    ) {
      setSplitTimecode({
        ...splitTimecode,
        error: true,
        errorMessage: `Split timecode must be greater than segment ${
          programInformation.slice(-2)[0].id + 1
        } start`,
      })
    }
  } else {
    currentSegmentCopy.error = false
    currentSegmentCopy.errorMessage = ''
  }
  setProgramInformation(programInformationCopy)
}

export const segmentValidation = (state) => {
  const { programInformation } = state
  programInformation.forEach((current, idx) => {
    const previous = programInformation[idx - 1]
      ? programInformation[idx - 1]
      : null
    const next = programInformation[idx + 1]
      ? programInformation[idx + 1]
      : null
    validateSegment(previous, current, next, state)
  })
}