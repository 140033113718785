import CssBaseline from '@mui/material/CssBaseline'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import React from 'react'
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { AuthProvider } from './context/AuthContext'
import PrivateRoute from './ui/components/PrivateRoute'
import LoginPage from './ui/views/loginPage'
import UploadPage from './ui/views/uploadPage'

function App() {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
      background: {
        default: '#272727',
        paper: '#252424',
      },
      primary: {
        main: '#272727',
      },
      secondary: {
        main: '#eaeaea',
      },
    },
    typography: {
      fontFamily: ['Montserrat', 'sans-serif'].join(','),
    },
    colors: {
      textColor: '#191919',
      primaryColor: '#383838',
      backgroundColor: '#d3d3d3',
    },
  })

  return (
    <ThemeProvider theme={darkTheme}>
      <Router>
        <CssBaseline />
        <AuthProvider>
          <Routes>
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <UploadPage />
                </PrivateRoute>
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="*" element={<Navigate to="/home" />} />
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  )
}

export default App
