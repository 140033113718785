import PropTypes from 'prop-types'
import React from 'react'
import icDeleteActive from '../../../images/icons/others/ic-delete-active.svg'
import CustomIconButton from './CustomIconButton'

export default function DeleteSegmentButton(props) {
  const { setProgramInformation, index } = props

  const deleteSegment = (idx) => {
    setProgramInformation((old) => {
      const copy = [...old]
      copy.splice(idx, 2)
      return copy
    })
  }

  return (
    <CustomIconButton
      tooltip="Delete segment"
      icon={icDeleteActive}
      alt="Trash bin icon"
      onClick={() => deleteSegment(index)}
      style={{ borderRadius: '1px' }}
      data-cy="delete-segment-button"
    />
  )
}

DeleteSegmentButton.propTypes = {
  setProgramInformation: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
}
