import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'
import LoginContainer from '../components/LoginContainer'
import Layout from '../layout'

export default function LoginPage() {
  const { user } = useContext(AuthContext)

  if (user) {
    return <Navigate to="/home" />
  }

  return (
    <Layout>
      <LoginContainer />
    </Layout>
  )
}
