import PropTypes from 'prop-types'
import React from 'react'
import ActionButton from './ActionButton'

export default function AddFilesButton(props) {
  const { onClick } = props

  return (
    <ActionButton
      alt="Add files button"
      buttonText="Add files"
      onClick={onClick}
      data-cy="add-files-button"
    />
  )
}

AddFilesButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}
