import Timecode from 'smpte-timecode'

export const isEmptyInput = (value) => !value || value === ''

export const isInvalidTimecode = (timecode) => {
  try {
    // will throw if the string contains invalid timecode, for example frame count above framerate
    Timecode(timecode, 25)
    return false
  } catch {
    return true
  }
}

export const isInvalidSplitTimecode = (split, lastSegmentStop) => {
  if (isInvalidTimecode(split) || isInvalidTimecode(lastSegmentStop))
    return true
  const splitTimecode = Timecode(split, 25)
  const lastSegmentStopTimecode = Timecode(lastSegmentStop, 25)
  const maxDifferenceInFrames = 15 * 25 // 15 seconds worth of frames
  return (
    splitTimecode.frameCount >
    lastSegmentStopTimecode.frameCount - maxDifferenceInFrames
  )
}

export const startLessThanEnd = (start, end) => {
  if (isInvalidTimecode(start) || isInvalidTimecode(end)) return true
  const tc1 = Timecode(start, 25)
  const tc2 = Timecode(end, 25)
  return tc1.valueOf() < tc2.valueOf()
}

export const startGreaterThanEnd = (start, end) => {
  if (isInvalidTimecode(start) || isInvalidTimecode(end)) return true
  const tc1 = Timecode(start, 25)
  const tc2 = Timecode(end, 25)
  return tc1.valueOf() > tc2.valueOf()
}

export const getFieldHelperText = (fieldObject) => {
  if (fieldObject.error) {
    return fieldObject.errorMessage
  }
  return ''
}
