import PropTypes from 'prop-types'
import React from 'react'
import { getFieldHelperText } from '../../utils/utils'
import MaskedTextField from '../components/textfield/MaskedTextField'
export default function SplitTimecodeField(props) {
  const { splitTimecode, setSplitTimecode, validateSplitTimecode } = props

  return (
    <MaskedTextField
      mask="99:99:99:99"
      id="split_timecode-input"
      name="split_timecode"
      placeholder="00:00:00:00"
      label="Split timecode"
      value={splitTimecode.value || ''}
      onChange={(e) =>
        setSplitTimecode({ ...splitTimecode, value: e.target.value })
      }
      onBlur={() => validateSplitTimecode()}
      error={splitTimecode.error}
      helperText={getFieldHelperText(splitTimecode)}
      required
    />
  )
}

SplitTimecodeField.propTypes = {
  splitTimecode: PropTypes.shape({
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  setSplitTimecode: PropTypes.func.isRequired,
  validateSplitTimecode: PropTypes.func.isRequired,
}
