import Grid2 from '@mui/material/Unstable_Grid2'
import React, { useContext } from 'react'
import AuthContext from '../../context/AuthContext'
import MTLogoAndSlogan from '../../images/logos/logowhite_with_slogan1_trimmed.png'
import LogoutButton from '../components/button/LogoutButton'

export default function Header() {
  const { user } = useContext(AuthContext)

  return (
    <>
      <Grid2
        xs={12}
        style={{
          paddingTop: '24px',
        }}
      >
        <Grid2 xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
          <img
            src={MTLogoAndSlogan}
            alt="Media Tailor logo and slogan"
            style={{
              maxWidth: '100%',
              width: '600px',
              display: 'block',
            }}
          />
        </Grid2>
      </Grid2>
      {user && (
        <div
          style={{
            position: 'absolute',
            top: '0px',
            right: '0px',
            padding: '5px',
          }}
        >
          <LogoutButton />
        </div>
      )}
    </>
  )
}
