import Grid2 from '@mui/material/Unstable_Grid2'
import PropTypes from 'prop-types'
import React from 'react'
import AddOrDeleteSegment from './AddOrDeleteSegment'
import HouseIdField from './HouseIdField'
import Segment from './Segment'
import SplitTimecodeField from './SplitTimecodeField'
import IntroField from './IntroField'

export default function ProgramInformationFields({
  programInformation, setProgramInformation, validateAllSegments,
  houseId, setHouseId, validateHouseId,
  splitTimecode, setSplitTimecode, validateSplitTimecode,
  introStart, setIntroStart, validateIntroStart,
  introEnd, setIntroEnd, validateIntroEnd,
}) {
  return (
    <Grid2 container spacing={2}>
      <Grid2 xs={12}>
        <span className="media-pocket-purple">Program information</span>
      </Grid2>
      <input
        type="hidden"
        id="Segment1StartHidden"
        name="Segment1Start"
        value="00:00:00:00"
      />
      {programInformation.map((segment, idx) => (
        <Grid2
          key={`segment-${segment.id}-${segment.segmentType}-container`}
          xs={12}
          sm={6}
          md={6}
          style={{ paddingBottom: '0px' }}
        >
          <Segment
            key={`segment-${segment.id}-${segment.segmentType}`}
            previous={
              programInformation[idx - 1] ? programInformation[idx - 1] : null
            }
            current={segment}
            next={
              programInformation[idx + 1] ? programInformation[idx + 1] : null
            }
            programInformation={programInformation}
            setProgramInformation={setProgramInformation}
            validateAllSegments={validateAllSegments}
          />
        </Grid2>
      ))}
      <Grid2
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '0px',
          alignItems: 'flex-start',
        }}
      >
        <AddOrDeleteSegment
          programInformation={programInformation}
          setProgramInformation={setProgramInformation}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <HouseIdField
          houseId={houseId}
          setHouseId={setHouseId}
          validateHouseId={validateHouseId}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <SplitTimecodeField
          splitTimecode={splitTimecode}
          setSplitTimecode={setSplitTimecode}
          validateSplitTimecode={validateSplitTimecode}
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <IntroField
          intro={introStart}
          setIntro={setIntroStart}
          validateIntro={validateIntroStart}
          position="Start"
        />
      </Grid2>
      <Grid2 xs={12} sm={6} md={6}>
        <IntroField
          intro={introEnd}
          setIntro={setIntroEnd}
          validateIntro={validateIntroEnd}
          position="End"
        />
      </Grid2>
    </Grid2>
  )
}

const inputFieldShape = {
  value: PropTypes.string,
  error: PropTypes.bool,
  errorMessage: PropTypes.string.isRequired,
  required: PropTypes.bool.isRequired,
}

ProgramInformationFields.propTypes = {
  programInformation: PropTypes.array.isRequired,
  setProgramInformation: PropTypes.func.isRequired,
  validateAllSegments: PropTypes.func.isRequired,

  houseId: PropTypes.shape(inputFieldShape).isRequired,
  setHouseId: PropTypes.func.isRequired,
  validateHouseId: PropTypes.func.isRequired,

  splitTimecode: PropTypes.shape(inputFieldShape).isRequired,
  setSplitTimecode: PropTypes.func.isRequired,
  validateSplitTimecode: PropTypes.func.isRequired,

  introStart: PropTypes.shape(inputFieldShape).isRequired,
  setIntroStart: PropTypes.func.isRequired,
  validateIntroStart: PropTypes.func.isRequired,

  introEnd: PropTypes.shape(inputFieldShape).isRequired,
  setIntroEnd: PropTypes.func.isRequired,
  validateIntroEnd: PropTypes.func.isRequired,
}
