import Grid2 from '@mui/material/Unstable_Grid2'
import React from 'react'

export default function Footer() {
  return (
    <Grid2
      xs={12}
      style={{
        display: 'flex',
        justifyContent: 'end',
        flexDirection: 'column',
      }}
    >
      <Grid2
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          paddingTop: '0px',
          whiteSpace: 'nowrap',
        }}
      >
        <a
          className="media-pocket-purple"
          href="https://mediatailor.fi/"
          target="_blank"
          rel="noreferrer"
          style={{
            marginLeft: '5px',
            marginRight: '5px',
            textUnderlineOffset: '2px',
          }}
        >
          mediatailor.fi
        </a>
        <a
          className="media-pocket-purple"
          href="https://mediapocket.io/"
          target="_blank"
          rel="noreferrer"
          style={{
            marginLeft: '5px',
            marginRight: '5px',
            textUnderlineOffset: '2px',
          }}
        >
          mediapocket.io
        </a>
      </Grid2>
    </Grid2>
  )
}
