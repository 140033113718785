import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import AuthContext from '../../context/AuthContext'

const PrivateRoute = ({ children }) => {
  const { user, refreshAuthToken, isAccessTokenExpired } =
    useContext(AuthContext)

  useEffect(() => {
    if (isAccessTokenExpired()) {
      refreshAuthToken()
    }
  }, [isAccessTokenExpired, refreshAuthToken])

  return user ? children : <Navigate to="/login" />
}

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateRoute
