import { Button, CircularProgress, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import React from 'react'

export default function ActionButton(props) {
  const {
    id,
    disabled,
    processing,
    onClick,
    buttonText,
    style,
    'data-cy': dataCy,
  } = props

  const CustomButton = styled(Button)`
    border-radius: 1px;
    background-color: #35958a;
    &:hover {
      background-color: rgba(53, 149, 138, 0.8);
    }
    ,
    &:disabled {
      opacity: 0.5;
    }
  `

  return (
    <CustomButton
      id={id}
      variant="string"
      disabled={disabled || processing}
      onClick={onClick}
      style={style}
      data-cy={dataCy}
    >
      {processing ? (
        <CircularProgress color="inherit" size={26} />
      ) : (
        <Typography
          style={{
            fontSize: '18px',
            fontWeight: 'normal',
            fontStyle: 'normal',
            fontStretch: 'normal',
            letterSpacing: 'normal',
            textAlign: 'left',
            color: '#eaeaea',
            textTransform: 'none',
          }}
        >
          {buttonText}
        </Typography>
      )}
    </CustomButton>
  )
}

ActionButton.defaultProps = {
  id: 'action-button',
  disabled: false,
  processing: false,
  style: {},
  'data-cy': 'action-button',
}

ActionButton.propTypes = {
  id: PropTypes.string,
  disabled: PropTypes.bool,
  processing: PropTypes.bool,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  'data-cy': PropTypes.string,
  onClick: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
}
