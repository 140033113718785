import {
  isEmptyInput,
  isInvalidSplitTimecode,
  isInvalidTimecode,
  startGreaterThanEnd,
} from '../../../utils/utils'

export const splitTimeCodeValidation = ({ splitTimecode, setSplitTimecode, programInformation }) => {
  if (isEmptyInput(splitTimecode.value)) {
    setSplitTimecode({
      ...splitTimecode,
      error: true,
      errorMessage: 'Split timecode is required',
    })
    return
  }
  if (isInvalidTimecode(splitTimecode.value)) {
    setSplitTimecode({
      ...splitTimecode,
      error: true,
      errorMessage: 'Please enter a valid timecode in format HH:MM:SS:FF',
    })
    return
  }
  if (isInvalidSplitTimecode(splitTimecode.value, programInformation.slice(-1)[0].value)) {
    setSplitTimecode({
      ...splitTimecode,
      error: true,
      errorMessage: `Split timecode must be at least 15 seconds before segment ${
        programInformation.slice(-1)[0].id + 1
      } end`,
    })
    return
  }
  if (!startGreaterThanEnd(splitTimecode.value, programInformation.slice(-2)[0].value)) {
    setSplitTimecode({
      ...splitTimecode,
      error: true,
      errorMessage: `Split timecode must be greater than segment ${
        programInformation.slice(-2)[0].id + 1
      } start`,
    })
    return
  }
  
  setSplitTimecode({ ...splitTimecode, error: false, errorMessage: '' })
}