import DOMPurify from 'dompurify'
import PropTypes from 'prop-types'
import React from 'react'

export default function SanitizeHTML(props) {
  const { html, options } = props

  const defaultOptions = {
    ALLOWED_TAGS: ['br'],
  }

  const sanitize = (dirty, options) => ({
    __html: DOMPurify.sanitize(dirty, { ...defaultOptions, ...options }),
  })

  return <div dangerouslySetInnerHTML={sanitize(html, options)} />
}

SanitizeHTML.defaultProps = {
  options: {},
}

SanitizeHTML.propTypes = {
  html: PropTypes.string.isRequired,
  options: PropTypes.object,
}
