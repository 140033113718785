import Grid2 from '@mui/material/Unstable_Grid2'
import Uppy from '@uppy/core'
import { Dashboard, DashboardModal, useUppy } from '@uppy/react'
import Tus from '@uppy/tus'
import React, { useContext, useState } from 'react'
import AuthContext from '../../context/AuthContext'
import Layout from '../layout'
import MetadataForm from '../metadata'

// And their styles (for UI plugins)
// With webpack and `style-loader`, you can import them like this:
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import AddFilesButton from '../components/button/AddFilesButton'
import SanitizeHTML from '../components/SanitizeHTML'

export default function UploadPage() {
  const [dashboardModalOpen, setDashboardModalOpen] = useState(false)
  const [triggerValidateFields, setTriggerValidateField] = useState(false)

  const { user, refreshAuthToken, logoutUser, isAccessTokenExpired } =
    useContext(AuthContext)
  const { show_metadata_form, username, description } = user

  const uppy = useUppy(() =>
    new Uppy({
      restrictions: {
        minNumberOfFiles: 1,
        maxNumberOfFiles: show_metadata_form ? 1 : null,
        allowedFileTypes: show_metadata_form ? ['.mxf'] : null,
        minFileSize: 1,
      },
      meta: {
        username,
      },
      onBeforeUpload: () => {
        if (!show_metadata_form) return true

        setTriggerValidateField(true)
        const { meta } = uppy.getState()
        const { readyForUpload } = meta
        if (!readyForUpload) {
          uppy.info(
            'Please fill all the required information fields',
            'error',
            15000
          )
        }
        setTriggerValidateField(false)
        return readyForUpload
      },
    }).use(Tus, {
      endpoint: '/api/upload/',
      chunkSize: 20971520,
      removeFingerprintOnSuccess: true,
      retryDelays: [0, 2000],
      async onBeforeRequest(req) {
        if (isAccessTokenExpired()) {
          await refreshAuthToken()
        }
        const token = JSON.parse(localStorage.getItem('authTokens'))?.access
        req.setHeader('Authorization', `Bearer ${token}`)
      },
      onShouldRetry(err, retryAttempt, options) {
        const body = err?.originalResponse?.getBody()
        let parsedBody = undefined
        if (body) {
          parsedBody = JSON.parse(body)
        }
        if (parsedBody?.code === 'user_not_found') {
          logoutUser()
          return false
        }
        if (err?.originalResponse?.getStatus() === 401) {
          return true
        }
        if (err?.originalResponse?.getStatus() === 400) {
          return false
        }
        return true
      },
      async onAfterResponse(req, res) {
        if (res.getStatus() === 401) {
          await refreshAuthToken()
        }
        if (res.getStatus() === 400) {
          uppy.info(res.getBody(), 'error', 15000)
        }
      },
    })
  )

  return (
    <>
      <Layout>
        {description && (
          <Grid2
            xs={12}
            className="media-pocket-green"
            style={{
              display: 'flex',
              justifyContent: 'center',
              textAlign: 'center',
            }}
          >
            <SanitizeHTML html={description} />
          </Grid2>
        )}
        {show_metadata_form ? (
          <>
            <Grid2
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <AddFilesButton
                onClick={() => setDashboardModalOpen(!dashboardModalOpen)}
              />
            </Grid2>
            <DashboardModal
              uppy={uppy}
              open={dashboardModalOpen}
              onRequestClose={() => setDashboardModalOpen(false)}
              closeModalOnClickOutside
              showProgressDetails
              theme="dark"
              note={
                show_metadata_form
                  ? 'Only MXF files are accepted, Video: 1080i50, XDCAM HD422, 50Mbps, Audio: Uncompressed (PCM), Stereo, 48kHz'
                  : null
              }
            />
            <MetadataForm
              uppy={uppy}
              triggerValidateFields={triggerValidateFields}
            />
          </>
        ) : (
          <Grid2 xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Dashboard
              uppy={uppy}
              showProgressDetails
              theme="dark"
              width={'calc(100vw - 30vw)'}
              height={'calc(100vh - 60vh)'}
            />
          </Grid2>
        )}
      </Layout>
    </>
  )
}
