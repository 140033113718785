import PropTypes from 'prop-types'
import React from 'react'
import { getFieldHelperText } from '../../utils/utils'
import MaskedTextField from '../components/textfield/MaskedTextField'

export default function Segment(props) {
  const {
    current,
    programInformation,
    setProgramInformation,
    validateAllSegments,
  } = props

  const setSegmentValue = (value) => {
    const programInformationCopy = [...programInformation]
    const currentSegment = programInformationCopy.find(
      (e) => e.id === current.id && e.segmentType === current.segmentType
    )
    currentSegment.value = value
    setProgramInformation(programInformationCopy)
  }

  return (
    <MaskedTextField
      mask="99:99:99:99"
      name={`Segment${current.id + 1}${current.segmentType}`}
      id={`Segment${current.id + 1}${current.segmentType}-input`}
      placeholder="00:00:00:00"
      label={`Segment ${current.id + 1} ${current.segmentType}`}
      value={current.value || ''}
      error={current.error}
      helperText={getFieldHelperText(current)}
      onChange={(e) => setSegmentValue(e.target.value)}
      onBlur={() => validateAllSegments()}
      disabled={current.id === 0 && current.segmentType === 'Start'} // Disable first segment start so its always 00:00:00:00
      required
    />
  )
}

Segment.defaultProps = {
  previous: null,
  next: null,
}

Segment.propTypes = {
  previous: PropTypes.shape({
    segmentType: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.number,
    required: PropTypes.bool,
  }),
  current: PropTypes.shape({
    segmentType: PropTypes.string.isRequired,
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.number.isRequired,
    required: PropTypes.bool.isRequired,
  }).isRequired,
  next: PropTypes.shape({
    segmentType: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    id: PropTypes.number,
    required: PropTypes.bool,
  }),
  programInformation: PropTypes.arrayOf(PropTypes.object).isRequired,
  setProgramInformation: PropTypes.func.isRequired,
  validateAllSegments: PropTypes.func.isRequired,
}
