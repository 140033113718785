import {
  isInvalidTimecode,
  startGreaterThanEnd,
  startLessThanEnd,
} from '../../../utils/utils'

const resetIntros = ({ introStart, introEnd, setIntroStart, setIntroEnd }) => {
  if (!introStart.value && !introEnd.value) {
    setIntroStart({ ...introStart, value: null, error: false, errorMessage: '' })
    setIntroEnd({ ...introEnd, value: null, error: false, errorMessage: '' })
    return true
  }
  return false
}

const introStartGreaterThanEnd = ({ introStart, introEnd, setIntroStart, setIntroEnd }) => {
  if (!introStart.value || !introEnd.value) return false
  if (!startLessThanEnd(introStart.value, introEnd.value)){
    setIntroEnd({
      ...introEnd,
      error: true,
      errorMessage: 'Intro end timecode must be greater than intro start timecode',
    })
    setIntroStart({
      ...introStart,
      error: true,
      errorMessage: 'Intro start timecode must be less than intro end timecode',
    })
    return true
  }
  return false
}

const invalidIntroTimecode = ({ introTime, setIntroTime }) => {
  if (isInvalidTimecode(introTime.value)) {
    setIntroTime({
      ...introTime,
      error: true,
      errorMessage: 'Please enter a valid timecode in format HH:MM:SS:FF',
    })
    return true
  }
  return false
}

const missingIntroValue = (absentTime, presentTime, setAbsent, position) => {
  if (!absentTime.value && presentTime.value) {
    setAbsent({
      ...absentTime,
      error: true,
      errorMessage: `Intro ${position} timecode is required when ${position === 'end' ? 'start' : 'end'} time code is provided`,
    })
    return true
  }
  return false
}

const introGreaterThanSegmentEnd = ({
  introTime, setIntroTime, position, programInformation,
}) => {
  const firstSegmentEnd = programInformation.find((segment) => segment.segmentType === 'End' && segment.id === 0)
  if (!startGreaterThanEnd(firstSegmentEnd.value, introTime.value)) {
    setIntroTime({
      ...introTime,
      error: true,
      errorMessage: `Intro ${position} timecode must be less than Segment 1 End timecode`,
    })
    return true
  }
  return false
}

export const introValidation = ({
  introStart, introEnd,
  setIntroStart, setIntroEnd,
  validateOther, position,
  programInformation
}) => {
  const introTime = position === 'start' ? introStart : introEnd
  const setIntroTime = position === 'start' ? setIntroStart : setIntroEnd

  if (resetIntros({ introStart, introEnd, setIntroStart, setIntroEnd })) return
  if (invalidIntroTimecode({ introTime, setIntroTime })) return
  if (introStartGreaterThanEnd({ introStart, introEnd, setIntroStart, setIntroEnd })) return
  if (introGreaterThanSegmentEnd({ introTime, setIntroTime, position, programInformation })) return

  setIntroTime({ ...introTime, error: false, errorMessage: '' })

  if (validateOther) {
    introValidation({
      introStart, introEnd,
      setIntroStart, setIntroEnd,
      position: position === 'start' ? 'end' : 'start',
      programInformation,
    })
  }
}

export const bothIntrosValidation = ({ introStart, introEnd, setIntroStart, setIntroEnd, programInformation }) => {
  if (resetIntros({ introStart, introEnd, setIntroStart, setIntroEnd })) return
  if (missingIntroValue(introStart, introEnd, setIntroStart, 'start')) return
  if (missingIntroValue(introEnd, introStart, setIntroEnd, 'end')) return
  if (introStart.value && introEnd.value) {
    ['start', 'end'].forEach((position) => {
      introValidation({
        introStart, introEnd,
        setIntroStart, setIntroEnd,
        position: position,
        programInformation,
      })
    })
  }
}