import PropTypes from 'prop-types'
import React from 'react'
import icPlusActive from '../../../images/icons/others/ic-plus-active.svg'
import CustomIconButton from './CustomIconButton'

export default function AddSegmentButton(props) {
  const { setProgramInformation } = props

  const addSegment = () => {
    setProgramInformation((old) => {
      const copy = [...old]
      const newStart = {
        segmentType: 'Start',
        value: null,
        error: null,
        id: copy.length / 2,
        required: true,
      }
      const newEnd = {
        segmentType: 'End',
        value: null,
        error: null,
        id: copy.length / 2,
        required: true,
      }
      copy.push(newStart, newEnd)
      return copy
    })
  }

  return (
    <CustomIconButton
      tooltip="Add segment"
      icon={icPlusActive}
      alt="Plus icon"
      onClick={() => addSegment()}
      style={{ borderRadius: '1px' }}
      data-cy="add-segment-button"
    />
  )
}

AddSegmentButton.propTypes = {
  setProgramInformation: PropTypes.func.isRequired,
}
