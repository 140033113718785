import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import ActionButton from './ActionButton'

export default function LoginButton(props) {
  const { loginDisabled, processingLogin, handleSubmit } = props

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        handleSubmit()
      }
    }
    document.addEventListener('keydown', listener)
    return () => {
      document.removeEventListener('keydown', listener)
    }
  })

  return (
    <ActionButton
      buttonText="Login"
      disabled={loginDisabled}
      onClick={handleSubmit}
      processing={processingLogin}
      style={{ width: '100px' }}
      data-cy="login-button"
    />
  )
}

LoginButton.propTypes = {
  loginDisabled: PropTypes.bool.isRequired,
  processingLogin: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
}
